// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-resizable > div:first-child {
  height: 100%;
}
.react-resizable-handle {
  visibility: visible !important;
  filter: invert(1);
  mix-blend-mode: difference;
}
`, "",{"version":3,"sources":["webpack://./panel.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;AACA;EACE,8BAA8B;EAC9B,iBAAiB;EACjB,0BAA0B;AAC5B","sourcesContent":[".react-resizable > div:first-child {\n  height: 100%;\n}\n.react-resizable-handle {\n  visibility: visible !important;\n  filter: invert(1);\n  mix-blend-mode: difference;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
